import { useQueryClient } from '@tanstack/react-query'
import { FC, useState } from 'react'
import { useDeletePortfolio } from '@netpurpose/api'
import { useModalContext } from '@netpurpose/core'
import { Button, ErrorMessage, Icon, Modal, Text, withTooltip } from '@netpurpose/np-ui'
import { MultiplePortfolioActionProps } from '#components/portfolio/portfolioTypes'
import { DeleteButtonContainer } from './DeletePortfolioAction.style'

export const DeletePortfolioModal: FC<MultiplePortfolioActionProps> = ({
  selectedPortfolioIds,
  setSelectedPortfolioIds,
}) => {
  const [error, setError] = useState<string | null>(null)
  const { isModalOpen, closeModal: closeActualModal } = useModalContext()
  const closeModal = () => closeActualModal('deletePortfolio')

  const { deletePortfolio } = useDeletePortfolio({
    onError: setError,
  })

  const queryClient = useQueryClient()

  const deletePortfolios = () => {
    selectedPortfolioIds.forEach((portfolioId) => {
      deletePortfolio(
        { portfolioId },
        {
          // will execute only once for the last item (we don't want this to be called for each item)
          // https://tanstack.com/query/latest/docs/framework/react/guides/mutations#consecutive-mutations
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['portfolios'],
            })
            setSelectedPortfolioIds([])
            closeModal()
          },
        },
      )
    })
  }

  return (
    <Modal
      open={isModalOpen.deletePortfolio}
      title="Delete Portfolio"
      onClose={closeModal}
      size="medium"
    >
      <Modal.ContentContainer>
        <Modal.HeaderText>Are you sure?</Modal.HeaderText>
        <Text color="typeSecondary">
          You are going to delete selected portfolio(s). This action is irreversible - are you sure
          you want to do this?
        </Text>
        {error && <ErrorMessage error={error} />}
      </Modal.ContentContainer>
      <Modal.FooterContainer>
        <Button level="danger" onClick={deletePortfolios}>
          Delete permanently
        </Button>
        <Button level="primary" onClick={closeModal}>
          Cancel
        </Button>
      </Modal.FooterContainer>
    </Modal>
  )
}

const DeleteButtonWrapper = withTooltip(DeleteButtonContainer)

export const DeletePortfolioAction: FC<
  Pick<MultiplePortfolioActionProps, 'selectedPortfolioIds'>
> = (props) => {
  const { openModal } = useModalContext()
  const { selectedPortfolioIds } = props

  const isDisabled = selectedPortfolioIds.length === 0
  const handleClick = () => {
    if (isDisabled) {
      return
    }
    openModal('deletePortfolio')
  }

  return (
    <>
      <DeleteButtonWrapper
        data-testid="delete-button"
        disabled={isDisabled}
        onClick={handleClick}
        tooltip="Select a portfolio to delete"
        showTooltip={isDisabled}
      >
        <Icon
          icon="Delete"
          alt="Delete portfolio"
          color="semanticNegative"
          style={isDisabled ? { opacity: 0.4 } : {}}
        />
      </DeleteButtonWrapper>
    </>
  )
}
