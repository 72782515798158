import { StyledButton } from './DownloadTemplateButton.style'

export const DownloadTemplateButton = ({
  onDownloadTemplate,
}: {
  onDownloadTemplate: () => void
}) => (
  <StyledButton
    level="link"
    download="Net Purpose - Create your portfolio template.csv"
    href="/downloads/portfolio-template.csv"
    onClick={onDownloadTemplate}
  >
    Download template
  </StyledButton>
)
