import styled from 'styled-components'
import { ThemeColor } from '../../themes'

type ContainerProps = {
  spacing: number
}

export const VerticalContainer = styled.div<ContainerProps>`
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 0 ${({ spacing }) => `${spacing * 0.5}rem`};
`
export const VerticalSpacer = styled.div<{ color: ThemeColor }>`
  height: 80%;
  width: 1px;
  background-color: ${({ theme, color }) => theme.colors[color]};
`
export const HorizontalContainer = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${({ spacing }) => `${spacing * 0.5}rem`} 0;
`
export const HorizontalSpacer = styled.div<{ color: ThemeColor }>`
  width: 100%;
  height: 1px;
  background-color: ${({ theme, color }) => theme.colors[color]};
`
