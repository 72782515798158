import { Segmented } from 'antd'
import styled from 'styled-components'

export const StyledAntdSegmented = styled(Segmented)<{
  $withMinWidth?: boolean
  $withReducedPadding?: boolean
}>`
  padding: 0;
  background-color: ${({ theme }) => theme.colors.architecture3};
  color: ${({ theme }) => theme.colors.typeSecondary};

  .ant-segmented-item-label {
    line-height: 1rem;
    /* Due to the way the elements are nested, we need to subtract 1px from the padding
       in order to avoid the overall component being slightly too tall. */
    padding: ${({ $withReducedPadding }) =>
      $withReducedPadding
        ? /* there is some odd bottom spacing that seems impossible to get rid off, so setting padding bottom to 0 for now */
          'calc(0.25rem - 1px) calc(0.5rem - 1px) 0 calc(0.5rem - 1px)'
        : 'calc(0.5rem - 1px) 1rem'};
  }

  .ant-segmented-item {
    min-width: ${({ $withMinWidth }) => ($withMinWidth ? '6rem' : 0)};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    /* To avoid component slightly decreasing in size when switching animation happens. */
    border: 1px solid transparent;
  }

  .ant-segmented-item-selected {
    color: ${({ theme }) => theme.colors.typePrimary};
    border: 1px solid ${({ theme }) => theme.colors.architecture8};
  }

  .ant-segmented-thumb-motion-appear-active {
    border: 1px solid ${({ theme }) => theme.colors.architecture8};
  }
`
