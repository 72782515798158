import styled from 'styled-components'

export const TitleWithTooltipContainer = styled.div<{
  $align: 'center' | 'flex-start' | undefined
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ $align }) => ($align ? $align : 'flex-end')};
  > div {
    margin-right: 1rem;
  }
`

export const TitleText = styled.div`
  text-align: left;
`
