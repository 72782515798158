import { Radio } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import styled from 'styled-components'

export const FileUploadWrapper = styled.div`
  display: flex;
  align-items: center;

  .ant-upload-list-item {
    height: 100%;
  }

  .ant-upload-list-item-name {
    width: 20rem;
    white-space: wrap;
  }
`

export const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
`

export const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.architecture3};

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`
export const ModalBodyContainer = styled.div<{ $visible: boolean; $morePadding?: boolean }>`
  display: ${({ $visible }) => ($visible ? 'flex' : 'none')};
  flex-direction: column;
  padding: 1rem 2rem;
  gap: ${({ $morePadding = false }) => ($morePadding ? '1.5rem' : '1rem')};

  .ant-form-item {
    margin-bottom: 0;
  }
`

export const FieldLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const StepCounter = styled.div<{ $active?: boolean }>`
  font-family: Blacker;
  border: 1px solid black;
  padding: 5px;
  border-radius: 50%;
  line-height: 0.65rem;
  font-size: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  text-align: center;
  background-color: ${({ $active, theme }) =>
    $active ? theme.colors.actionPrimary : 'transparent'};
`

export const LabelText = styled.div`
  color: ${({ theme }) => theme.colors.typeSecondary};
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1rem;
  text-transform: uppercase;
`

export const DragUpload = styled(Dragger)`
  &.ant-upload.ant-upload-drag {
    background-color: ${({ theme }) => theme.colors.bgSecondary};
    height: 13rem;
  }
`

export const UploadedFileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;

  > span:first-of-type {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`
export const ModalText = styled.span`
  font-size: 0.875rem;
  line-height: 1rem;
`

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TextList = styled.ol`
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding-inline-start: 1rem;
  margin-bottom: 0;

  > li {
    margin-top: 0.5rem;
  }
`

export const Separator = styled.span`
  color: ${({ theme }) => theme.colors.architecture3};
`

export const FileUploadedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > span {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
`

export const RadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const RadioButton = styled(Radio)`
  .ant-radio {
    display: none;
  }
  > span {
    width: 100%;
    padding: 0;
  }
  width: 100%;

  &.ant-radio-wrapper-checked {
    > span {
      background-color: ${({ theme }) => theme.colors.bgPrimary};
      > div {
        border-color: ${({ theme }) => theme.colors.architecture8};
      }
    }
  }
`

export const RadioContent = styled.div`
  display: flex;
  flex-direction: column;

  > span:first-child {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  > span:last-child {
    font-size: 0.875rem;
    line-height: 1rem;
  }
`

export const RadioContainer = styled.div`
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.architecture3};
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  gap: 1rem;
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 2fr 1.6fr;
  grid-template-rows: 0.1fr 0.9fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0.25rem;
  width: 80%;
`
