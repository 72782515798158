import { IconWithTooltip } from '@netpurpose/np-ui'
import { TitleText, TitleWithTooltipContainer } from './ColumnTitleWithInfoTooltip.style'

type Props = {
  title: string
  text: string | React.ReactNode
  withoutPadding?: boolean
  align?: 'center' | 'flex-start'
  logTooltipHoverEvent: (tooltipName: string) => void
}

export const ColumnTitleWithInfoTooltip = ({
  title,
  text,
  logTooltipHoverEvent,
  withoutPadding = false,
  align,
}: Props) => (
  <TitleWithTooltipContainer $align={align}>
    <TitleText>{title}</TitleText>
    <IconWithTooltip
      icon="Info"
      alt="info"
      color="typeSecondary"
      tooltip={text}
      size="s"
      tooltipPlacement="bottom"
      onHover={() => logTooltipHoverEvent(title)}
      withoutPadding={withoutPadding}
      minimalPadding
      hideTooltip
      width={16}
      height={16}
    />
  </TitleWithTooltipContainer>
)
