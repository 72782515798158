import { FC } from 'react'
import { usePaginatedPortfolios } from '@netpurpose/api'
import { useModalContext } from '@netpurpose/core'
import { PageSection } from '@netpurpose/np-ui'
import { LoggedInLayout } from '#components/common/Layout'
import { ShowIfLoggedIn } from '#components/common/ShowIfLoggedIn'
import { PortfolioTable } from '#components/portfolio/PortfolioTable'
import { usePortfolioSettings } from '#context/portfolio'
import { useTasks } from '#context/tasks'
import { CreatePortfolioModal, CreatePortfolioModalOld } from '../CreatePortfolioAction'
import { DeletePortfolioModal } from '../DeletePortfolioAction'
import Actions from './Actions'

const PortfolioList: FC = () => {
  const { selectedPortfolioIds, setSelectedPortfolioIds } = usePortfolioSettings()
  const { data, isFetching, filterConfig, paginationConfig } = usePaginatedPortfolios({})
  const { portfolioCreationTasks } = useTasks()
  const { isModalOpen } = useModalContext()

  const portfolios = data?.results
  const pendingPortfolios = portfolioCreationTasks.filter((task) => task.status === 'pending')

  return (
    <LoggedInLayout title="Portfolios">
      <PageSection
        title="Portfolios"
        headingLevel={2}
        actions={{
          Component: Actions,
          props: {
            selectedPortfolioIds,
            setSelectedPortfolioIds,
          },
        }}
      >
        <PortfolioTable
          portfolios={portfolios || []}
          pendingPortfolios={pendingPortfolios}
          isLoading={isFetching}
          selectedPortfolioIds={selectedPortfolioIds}
          setSelectedPortfolioIds={setSelectedPortfolioIds}
          filterConfig={filterConfig}
          paginationConfig={paginationConfig}
        />
      </PageSection>
      {isModalOpen.deletePortfolio && (
        <DeletePortfolioModal
          selectedPortfolioIds={selectedPortfolioIds}
          setSelectedPortfolioIds={setSelectedPortfolioIds}
        />
      )}
      {isModalOpen.createPortfolio && <CreatePortfolioModal />}
      {isModalOpen.createPortfolioOld && <CreatePortfolioModalOld />}
    </LoggedInLayout>
  )
}

const ProtectedPortfolioList = () => (
  <ShowIfLoggedIn>
    <PortfolioList />
  </ShowIfLoggedIn>
)

export default ProtectedPortfolioList
