import { Portfolio } from '@netpurpose/api'

export type MultiplePortfolioActionProps = {
  selectedPortfolioIds: Portfolio['id'][]
  setSelectedPortfolioIds: (selectedIds: Portfolio['id'][]) => void
}

export type ModalActionTrigger = {
  onClick: () => void
}

export const portfolioTypeMap: Record<string, string> = {
  private: 'Portfolio',
  benchmark: 'Benchmark',
  watchlist: 'Watchlist',
}
export const formatPortfolioTypes = (types?: string[]): string[] =>
  types ? types.map((e: string) => (e in portfolioTypeMap ? portfolioTypeMap[e] || e : e)) : []
