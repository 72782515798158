import { FC } from 'react'
import { ThemeColor } from '../../themes'
import {
  HorizontalContainer,
  HorizontalSpacer,
  VerticalContainer,
  VerticalSpacer,
} from './Spacer.style'

type Props = {
  orientation: 'vertical' | 'horizontal'
  spacing?: number
  color?: ThemeColor
}

const Spacer: FC<Props> = ({ orientation, spacing = 1, color = 'architecture2' }) => {
  if (orientation === 'vertical') {
    return (
      <VerticalContainer spacing={spacing}>
        <VerticalSpacer color={color} />
      </VerticalContainer>
    )
  }
  return (
    <HorizontalContainer spacing={spacing} color={color}>
      <HorizontalSpacer color={color} data-testid="spacer" />
    </HorizontalContainer>
  )
}

export default Spacer
