import styled from 'styled-components'

export const LinkText = styled.span<{ $bold?: boolean }>`
  color: ${({ theme }) => theme.colors.actionLink};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')};
`

export const MonospacedLinkText = styled(LinkText)`
  font-family: 'DM Mono', monospace;
`
