import styled from 'styled-components'
import { Heading } from '@netpurpose/np-ui'

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0 1.5rem 0;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  margin: 2.5rem 1.25rem;
  > * + * {
    margin-top: 2rem;
  }
`

export const EmptyHeadingText = styled(Heading)`
  margin-bottom: 0;
`
