type GetPortfolioIdsProps = {
  newSelectedRowId: number
  selectedPortfolioIds: number[]
  isBeingChecked: boolean
}

export const getPortfolioIds = ({
  newSelectedRowId,
  selectedPortfolioIds,
  isBeingChecked,
}: GetPortfolioIdsProps): number[] => {
  if (!isBeingChecked) {
    return selectedPortfolioIds.filter((value: number) => value !== newSelectedRowId)
  }

  return [...selectedPortfolioIds, newSelectedRowId]
}
