import { FC } from 'react'
import { Icon } from '../Icon'
import { ErrorMessageContainer, WarningText } from './ErrorMessage.style'

type Props = {
  error: string
  smallText?: boolean
}

export const ErrorMessage: FC<Props> = ({ error }) => {
  return (
    <ErrorMessageContainer>
      <Icon icon="Warning" alt="Warning" color="semanticNegative" />
      <WarningText>{error}</WarningText>
    </ErrorMessageContainer>
  )
}
