import styled from 'styled-components'
import { PortfolioType } from '@netpurpose/api'

export const Badge = styled.div<{ type: PortfolioType }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.625rem;
  font-weight: 700;
  font-size: 0.6875rem;
  width: 1.25rem;
  height: 1.25rem;

  background-color: ${({ theme, type }) =>
    type === 'benchmark' ? theme.colors.architecture5 : theme.colors.black};
  color: ${({ theme }) => theme.colors.typeDarkPrimary};
`
