import { FC } from 'react'
import styled from 'styled-components'

type Props = {
  name: string
  alt?: string
  className?: string
  // Used for compatibility with Storybook.
  pathPrefix?: string
}

const BlockImg = styled.img`
  display: block;
`

const Image: FC<Props> = ({ name, alt = 'Image', className, pathPrefix = '' }) => {
  return (
    <div className={className}>
      <BlockImg src={`${pathPrefix}/images/${name}`} alt={alt} height="100%" width="100%" />
    </div>
  )
}

export default Image
