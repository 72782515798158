import { FC } from 'react'
import { Button, ButtonWithIcon, Heading, Text } from '@netpurpose/np-ui'
import { CreatePortfolioAction } from '#components/portfolio/CreatePortfolioAction'
import { ModalActionTrigger } from '#components/portfolio/portfolioTypes'
import { UploadHelperAction, UploadHelperModal } from '#components/portfolio/UploadHelperAction'
import { LearnMoreContainer } from './EmptyPortfolioTable.style'
import { EmptyContainer, EmptyHeadingText } from './PortfolioTable.style'

const CreateButton: FC<ModalActionTrigger> = ({ onClick }) => (
  <ButtonWithIcon
    level="tertiary"
    icon="Plus"
    iconAlt="Create portfolio icon"
    color="black"
    onClick={onClick}
  >
    Create a Portfolio
  </ButtonWithIcon>
)

const LearnMoreLink: FC<ModalActionTrigger> = ({ onClick }) => (
  <Button level="link" onClick={onClick}>
    Learn more
  </Button>
)

export const EmptyPortfolioTable = () => (
  <>
    <EmptyContainer>
      <div>
        <EmptyHeadingText level={1}>Create a Portfolio</EmptyHeadingText>
        <Heading level={3} color="typeSecondary">
          Measure your contribution to our global goals.
        </Heading>
      </div>
      <CreatePortfolioAction renderAction={CreateButton} />
      <LearnMoreContainer>
        <Text>Not sure how to upload a portfolio?</Text>
        <UploadHelperAction renderAction={LearnMoreLink} />
      </LearnMoreContainer>
    </EmptyContainer>
    <UploadHelperModal />
  </>
)
