import { SegmentedValue } from 'antd/lib/segmented'
import { useQueryParams } from '@netpurpose/core'
import { StyledAntdSegmented } from './SwitchButton.style'

type Props<Value extends SegmentedValue> = {
  options: {
    label: string | React.ReactElement
    value: Value
  }[]
  defaultValue: Value
  onClick?: (value: Value) => void
  withMinWidth?: boolean
  withReducedPadding?: boolean
  queryParamKey?: string
  fullWidth?: boolean
}

export const SwitchButton = <Value extends SegmentedValue>({
  options,
  defaultValue,
  onClick,
  withMinWidth = true,
  withReducedPadding = false,
  queryParamKey = 'activeView',
  fullWidth = false,
}: Props<Value>) => {
  const { replaceQueryParam, getQueryParam } = useQueryParams()
  const activeView = getQueryParam(queryParamKey) || defaultValue

  return (
    <StyledAntdSegmented
      options={options.map((option) => ({
        ...option,
        // Prevent tooltip rendering on buttons.
        title: '',
      }))}
      value={activeView}
      onChange={(value) => {
        replaceQueryParam({ [queryParamKey]: value as string })
        onClick?.(value as Value)
      }}
      $withMinWidth={withMinWidth}
      $withReducedPadding={withReducedPadding}
      block={fullWidth}
    />
  )
}
