import styled from 'styled-components'

export const ErrorMessageContainer = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr;
  padding-top: 1rem;
  align-items: center;
`
export const WarningText = styled.div`
  color: ${({ theme }) => theme.colors.semanticNegative};
  font-weight: 700;
`
