import { useQuery } from '@tanstack/react-query'
import { TWELVE_HOURS_MS } from '@netpurpose/types'
import { useApi } from '../useApi'

export const useEnum = <Enum extends string>(enumName: string, options?: { enabled: boolean }) => {
  const { api } = useApi()
  return useQuery({
    queryKey: ['enum', enumName],
    queryFn: () => api.enum.getEnum<Enum>(enumName),
    ...options,
    staleTime: TWELVE_HOURS_MS,
  })
}
